import React, { useState, useEffect } from 'react';
import '../../../css/mainPage/component9.css'; // Импорт соответствующего файла стилей
import build1 from '../../../assest/svgEl/about_us/build1.svg';
import sky from '../../../assest/svgEl/about_us/sky.svg';
import build21 from '../../../assest/svgEl/about_us/build21.svg';
import build22 from '../../../assest/svgEl/about_us/build22.svg';
import build31 from '../../../assest/svgEl/about_us/build31.svg';
import build32 from '../../../assest/svgEl/about_us/build32.svg';
import build33 from '../../../assest/svgEl/about_us/build33.svg';
import cup from '../../../assest/svgEl/about_us/cup.svg';
import truck from '../../../assest/svgEl/about_us/truck.svg';
import pipe from '../../../assest/svgEl/about_us/pipe.svg';
import build4 from '../../../assest/svgEl/about_us/build4.svg';
import smoke1 from '../../../assest/svgEl/about_us/smoke1.svg';
import smoke from '../../../assest/svgEl/about_us/smoke.svg';
import info from '../../../assest/svgEl/about_us/info.svg';
import { Link } from 'react-router-dom';

const Component9 = () => {
  const info6 =
    'Основное направление деятельности оконного завода SCHTERN - полный производственный цикл изготовления светопрозрачных конструкций, начиная с собственной экструзии ПВХ профиля, изготовления стеклопакетов, сборкой готовых изделий и доставкой продукции клиентам компании.';

  const [smoke11, setSmoke11] = useState('smoke11-none');
  const [smoke12, setSmoke12] = useState('smoke12-none');
  const [build2, setBuild2] = useState(build21);
  const [build3, setBuild3] = useState(build31);
  const [smoke21, setSmoke21] = useState('smoke21-none');
  const [smoke22, setSmoke22] = useState('smoke22-none');
  const [activeInfo1, setActiveInfo1] = useState('non-active');
  const [activeInfo2, setActiveInfo2] = useState('non-active');
  const [activeInfo3, setActiveInfo3] = useState('non-active');
  const [activeInfo4, setActiveInfo4] = useState('non-active');
  const [activeInfo5, setActiveInfo5] = useState('non-active');
  const [activeInfo6, setActiveInfo6] = useState(info6);

  const [isDesktop, setIsDesktop] = useState(window.innerWidth);
  const handleResize = () => {
    setIsDesktop(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const elements = [
    {
      name: 'info1',
      text: 'Производственные мощности компании расположены в Центральном Федеральном округе России, в городе Липецке, на территории площадью 75 тыс.кв.м.',
      function: setActiveInfo1,
      state: activeInfo1
    },
    {
      name: 'info2',
      text: 'Цех по производству профиля ПВХ работает на оборудовании, изготовленном европейскими компаниями CINCINNATI, Gruber, итальянских PENТA, PLASMEQ.',
      function: setActiveInfo2,
      state: activeInfo2
    },
    {
      name: 'info3',
      text: 'Внедрение инновационного оборудования позволило увеличить эффективность производства оконной компании более чем в 3 раза и снизить энергозатраты на производство одного окна более чем на 30%.',
      function: setActiveInfo3,
      state: activeInfo3
    },
    {
      name: 'info4',
      text: 'Новое производство стеклопакетов на оборудовании ведущего мирового производителя австрийской компании LISEC включает в себя две производственные линии с суммарной производительностью 1600 стеклопакетов за 8 часов.',
      function: setActiveInfo4,
      state: activeInfo4
    },
    {
      name: 'info5',
      text: 'Цех экструзии производит 12, 6 миллионов метров профиля SCHTERN в год.',
      function: setActiveInfo5,
      state: activeInfo5
    }
  ];

  const smokeOnFunc = () => {
    setSmoke21('smoke21');
    setSmoke22('smoke22');
    setBuild3(build33);
  };

  const smokeOffFunc = () => {
    setSmoke21('smoke21-none');
    setSmoke22('smoke22-none');
    setBuild3(build31);
  };

  const handleButtonClick = (name) => {
    console.log(name);
  };

  const turnOnText = (index) => {
    elements.map((element, index) => {
      element.function('non-active');
    });
    elements[index].function('active');
  };

  const turnOffText = (index) => {
    elements.map((element, index) => {
      element.function('non-active');
    });
  };

  return (
    <div className="component9">
      <div>
        <div className="bg-c9">
          <div className="background-с9"></div>
          <img src={smoke} alt="smoke11" className={`${smoke11}`} />
          <img src={smoke} alt="smoke12" className={`${smoke12}`} />
          <img src={smoke1} alt="smoke21" className={`${smoke21}`} />
          <img src={smoke1} alt="smoke22" className={`${smoke22}`} />
          <img
            src={build1}
            alt="build1"
            className="build1"
            onMouseEnter={() => smokeOnFunc()}
            onMouseLeave={() => smokeOffFunc()}
          />
          <img
            src={build3}
            alt="build31"
            className="build31"
            onMouseEnter={() => setBuild3(build32)}
            onMouseLeave={() => setBuild3(build31)}
          />
          <img
            src={build2}
            alt="build21"
            className="build21"
            onMouseEnter={() => setBuild2(build22)}
            onMouseLeave={() => setBuild2(build21)}
          />
          <img src={cup} alt="cup" className="cup" />
          <img
            src={pipe}
            alt="pipe"
            className="pipe1"
            onMouseEnter={() => setSmoke11('smoke12')}
            onMouseLeave={() => setSmoke11('smoke12-none')}
          />
          <img
            src={pipe}
            alt="pipe"
            className="pipe2"
            onMouseEnter={() => setSmoke11('smoke11')}
            onMouseLeave={() => setSmoke11('smoke11-none')}
          />
          <img src={build4} alt="build4" className="build4" />
          <img src={truck} alt="truck" className="truck" />

          {elements.map((element, index) => (
            <div>
              <img
                src={info}
                alt={element.name}
                className={`${element.name}-c9`}
                onClick={() => turnOnText(index)}
              />
              <div className={`info-board-${index}-${element.state}`}>
                <div className={`info-text-${index}`}>{element.text}</div>
                <button className="toggle-button-c9" onClick={() => turnOffText()}>
                  &times;
                </button>
              </div>
            </div>
          ))}
        </div>
        <div className="aboutUs-c9"> О нас</div>
        {isDesktop > 480 ? (
          <div className="info-c9">{activeInfo6}</div>
        ) : (
          <div className="info-c9">{activeInfo6}</div>
        )}
        <Link to="/o-kompanii">
          <div className="more-c9">Подробнее о компании</div>
        </Link>
      </div>
    </div>
  );
};

export default Component9;

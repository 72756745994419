import React, { useEffect, useState } from 'react';
import '../css/Kontakty.css';
import pic from '../../src/assest/svgEl/contacts/pic.svg';
import person from '../../src/assest/svgEl/contacts/man.svg';
import flower from '../../src/assest/svgEl/contacts/flower.svg';

import Stuff from './Stuff';
import Component13 from './Elements/mainPage/Component13';
import UpperMenu from './Elements/UpperMenu';

const Kontakty = () => {
  useEffect(() => {
    window.scrollTo(0, 0); // Прокрутить вверх при монтировании
  }, []);

  return (
    <>
      <UpperMenu />
      <div className="contacts">
        <div className="company-card">
          <h2>Контакты</h2>
          <div className="flower-contacts">
            <img src={flower} alt="flower" />
          </div>
          <div className="person-contacts">
            <img src={person} alt="person" />
          </div>
          <div className="pic-contacts">
            <img src={pic} alt="pic" />
          </div>
          <div className="banner-container-contacts">
            <div className="banner-contacts">
              <h1 >Первый оконный завод в г. Липецке</h1>
              <div className="contacts-contscts">
                <div class="text-block1-contacts">Адрес:</div>
                <div class="text-block2-contacts">Телефон:</div>
                <div class="text-block3-contacts">
                  г. Липецк, <br />
                  ул. Римского-Корсакова, 10А
                </div>
                <div class="text-block4-contacts">
                  <a href="tel:+78005505878">8 800 550-58-78</a>
                  <a href="tel:+74742242924">+7 (4742) 24-29-24</a>
                </div>

                <div class="text-block5-contacts">Почта:</div>
                <div class="text-block6-contacts">График работы:</div>
                <div class="text-block7-contacts">shop@ok-schtern.ru</div>
                <div class="text-block8-contacts">
                  Пн. – Пт.:
                  <br />с 8:00 до 17:00
                  <br />Обеденный перерыв
                  <br />с 12:00 до 13:00
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div style={{
        marginTop: '5vh',
      }}><Stuff /></div>
      <Component13 />
    </>
  );
};

export default Kontakty;

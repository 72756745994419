import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import './OKompanii.css';
import icon1 from './images/icon1.svg';
import icon2 from './images/icon2.svg';
import icon3 from './images/icon3.svg';
import icon4 from './images/icon4.svg';
import icon5 from './images/icon5.svg';
import UpperMenu from '../../../components/Elements/UpperMenu.jsx';
import Component13 from '../../../components/Elements/mainPage/Component13.jsx';
import Sertifikaty from './Sertifikaty.jsx';
import bg from './images/bg_okompanii.svg';
import mob_bg from './images/mob_bg_okompanii.svg';
import mob_bg2 from './images/mob_bg_okompanii2.svg';

const Istoriya = () => {
  const [isDesktop, setIsDesktop] = useState(window.innerWidth);
  const handleResize = () => {
    setIsDesktop(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <>
      <Helmet>
        <title>Пластиковые Окна Окна ПВХ</title>
        <meta
          name="description"
          content="Пластиковые окна

          Пластиковые окна Пластиковые окна — это современное и практичное решение для вашего дома или квартиры. Они обладают множеством преимуществ, которые делают их лучшим выбором для остекления любых помещений. Вот некоторые из них:

          - Изделия из ПВХ имеют высокую теплоизоляцию и энергоэффективность. Они не пропускают холод и сквозняки, сохраняют тепло внутри и снижают расходы на отопление. Такие окна также защищают от перегрева в жаркое время года, благодаря специальным стеклопакетам с солнцезащитой.
          - Эти конструкции обеспечивают хорошую защиту от шума. Они изолируют внешние звуки и создают тихую и уютную атмосферу в доме. Они также устойчивы к взлому и повреждениям, благодаря качественной фурнитуре и противовзломным системам.
          - Оконные рамы из пластика легко устанавливаются и обслуживаются. Они не нуждаются в покраске или ремонте, не коррозируют и не деформируются. Они легко моются и не теряют свой цвет и блеск со временем. Они также имеют долгий срок службы — до 50 лет и более.
          - Оконные блоки из пластика имеют разнообразный дизайн и подходят для любого интерьера. Вы можете выбрать их любого вида, размера и формы: арочные, эркерные, панорамные, раздвижные, подъемно-раздвижные и другие. Вы также можете выбрать их любого цвета: белые, черные, цветные, ламинированные под дерево или камень.
          - Они подходят для остекления любых помещений: квартир, домов, балконов, лоджий, террас, веранд, детских, коттеджей и других. Они создают комфорт и улучшают микроклимат в помещениях. Они обеспечивают свежий воздух и оптимальную влажность, благодаря системам вентиляции и конденсата. Они также предотвращают появление плесени и грибка, которые могут нанести вред здоровью.
          - Они экологичны и безопасны для окружающей среды. Они не содержат вредных веществ и не выделяют токсичных газов. Они также экономят электроэнергию и снижают выбросы углекислого газа. Они изготавливаются из перерабатываемых материалов и могут быть утилизированы после окончания срока службы.
          Как вы видите, пластиковые окна — это идеальный вариант для вашего дома или квартиры. Они сочетают в себе качество, функциональность, эстетику и экономию. "
        />
        <meta property="og:title" content="Пластиковые Окна Окна ПВХ" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://ok-schtern.ru/" />
        <meta property="og:image" content="URL_ИЗОБРАЖЕНИЯ" />
        <meta
          property="og:description"
          content="Пластиковые окна — это современное и практичное решение для вашего дома или квартиры."
        />
        <meta property="og:site_name" content="SCHTERN" />
        <meta property="og:locale" content="ru_RU" />
      </Helmet>

      {isDesktop > 480 ? (
        <>
          <UpperMenu />
          <div className="o-kompanii__top">
            <img src={bg} alt="" />
          </div>
          <div className="o-kompanii">
            <h2 className="o-kompanii__h1">О компании</h2>
            <h2 className="o-kompanii__h2">
              Основное направление деятельности оконного завода SCHTERN - полный производственный цикл изготовления
              светопрозрачных конструкций, начиная с собственной экструзии ПВХ профиля, изготовления
              стеклопакетов, сборкой готовых изделий и доставкой продукции Клиентам компании.
            </h2>
            <div className="o-kompanii__first-line">
              <div>
                <div className="o-kompanii__first-line--big">12,6</div>
                <div className="o-kompanii__first-line--small">
                  миллионов метров профиля <br></br>SCHTERN в год
                </div>
              </div>

              <div>
                <div className="o-kompanii__first-line--big"> 75</div>
                <div className="o-kompanii__first-line--small">
                  тыс. кв. м площадь производственной <br />
                  мощности в городе Липецк
                </div>
              </div>
            </div>
            <div className="o-kompanii__greeds">
              <div className="o-kompanii__grids--greed">
                <img src={icon1} alt="alt" />
                <p className="o-kompanii__grids--greed--half">
                  Внедрение инновационного оборудования позволило увеличить эффективность
                  производства оконной компании более чем в 3 раза и снизить энергозатраты на
                  производство одного окна более чем на 30%.
                </p>
              </div>
              <div className="o-kompanii__grids--greed">
                <img src={icon2} alt="alt" />
                <p className="o-kompanii__grids--greed--half">
                  Новое производство стеклопакетов на оборудовании ведущего мирового производителя
                  австрийской компании LISEC включает в себя две производственные линии с суммарной
                  производительностью 1600 стеклопакетов за 8 часов.
                </p>
              </div>
              <div className="o-kompanii__grids--greed">
                <img src={icon3} alt="alt" />
                <p className="o-kompanii__grids--greed--half">
                  В новом цехе установлено высокопроизводительное современное оборудование ведущих
                  мировых производителей Германии и Австрии.
                </p>
              </div>
              <div className="o-kompanii__grids--greed">
                <img src={icon4} alt="alt" />
                <p className="o-kompanii__grids--greed--half">
                  Общая производительность нового производства оконных блоков составляет 480 изделий
                  за 8 часов, что позволяет выпускать примерно 1440 стандартных изделий в сутки.
                  Общая производственная мощность компании «УК ШТЕРН», благодаря внедрению новой
                  линии составляет 3000 стандартных изделий в сутки, или около 160 тыс. кв. метров в
                  месяц.
                </p>
              </div>
              <div className="o-kompanii__grids--greed">
                <img src={icon5} alt="alt" />
                <p className="o-kompanii__grids--greed--full">
                  Цех по производству профиля ПВХ работает на оборудовании, изготовленном
                  европейскими компаниями CINCINNATI, Gruber, итальянских PENТA, PLASMEQ. При
                  производстве профиля применяются высококачественные материалы: ПВХ марки SHINTEC
                  (США), стабилизаторы Ika (Германия), модификаторы фирмы KANEKA (Бельгия), диоксид
                  титана фирмы DuPont (США). Мягкий ПВХ для уплотнительной резинки - фирмы BEGRA
                  (Германия).
                </p>
              </div>
            </div>
          </div>
          <Component13 />
        </>
      ) : (
        <>
          {' '}
          <UpperMenu />
          <div className="mob_o-kompanii__top">
            <img src={mob_bg} alt="" />
          </div>
          <div className="o-kompanii">
            <h2 className="o-kompanii__h1">О компании</h2>
            <h2 className="o-kompanii__h2">
              Основное направление деятельности оконного завода SCHTERN - полный производственный цикл изготовления
              светопрозрачных конструкций, начиная с собственной экструзии ПВХ профиля, изготовления
              стеклопакетов, сборкой готовых изделий и доставкой продукции Клиентам компании.
            </h2>
            <div className="o-kompanii__first-line_mob">
              <div className="o-kompanii__first-line--container_mob">
                <div className="o-kompanii__first-line--big_mob">12,6</div>
                <div className="o-kompanii__first-line--small_mob">
                  миллионов метров профиля <br></br>SCHTERN в год
                </div>
              </div>

              <div className="o-kompanii__first-line--container_mob">
                <div className="o-kompanii__first-line--big_mob"> 75</div>
                <div className="o-kompanii__first-line--small_mob">
                  тыс. кв. м площадь производственной <br />
                  мощности <br />в городе Липецк
                </div>
              </div>
            </div>
            <div className="o-kompanii__container">
              <div className="o-kompanii__container--item">
                <img src={icon1} alt="alt" />
                <p className="o-kompanii__grids--greed--half">
                  Внедрение инновационного оборудования позволило увеличить эффективность
                  производства оконной компании более чем в 3 раза и снизить энергозатраты на
                  производство одного окна более чем на 30%.
                </p>
              </div>
              <div className="o-kompanii__container--item">
                <img src={icon2} alt="alt" />
                <p className="o-kompanii__grids--greed--half">
                  Новое производство стеклопакетов на оборудовании ведущего мирового производителя
                  австрийской компании LISEC включает в себя две производственные линии с суммарной
                  производительностью 1600 стеклопакетов за 8 часов.
                </p>
              </div>
              <div className="o-kompanii__container--item">
                <img src={icon3} alt="alt" />
                <p className="o-kompanii__grids--greed--half">
                  В новом цехе установлено высокопроизводительное современное оборудование ведущих
                  мировых производителей Германии и Австрии.
                </p>
              </div>
              <div className="o-kompanii__container--item">
                <img src={icon4} alt="alt" />
                <p className="o-kompanii__grids--greed--half">
                  Общая производительность нового производства оконных блоков составляет 480 изделий
                  за 8 часов, что позволяет выпускать примерно 1440 стандартных изделий в сутки.
                  Общая производственная мощность компании «УК ШТЕРН», благодаря внедрению новой
                  линии составляет 3000 стандартных изделий в сутки, или около 160 тыс. кв. метров в
                  месяц.
                </p>
              </div>
              <div className="o-kompanii__container--item">
                <img src={icon5} alt="alt" />
                <p className="o-kompanii__grids--greed--full">
                  Цех по производству профиля ПВХ работает на оборудовании, изготовленном
                  европейскими компаниями CINCINNATI, Gruber, итальянских PENТA, PLASMEQ. При
                  производстве профиля применяются высококачественные материалы: <br />
                  ПВХ марки SHINTEC (США), стабилизаторы Ika (Германия), модификаторы фирмы KANEKA
                  (Бельгия), диоксид титана фирмы DuPont (США). Мягкий ПВХ для уплотнительной
                  резинки - фирмы BEGRA (Германия).
                </p>
              </div>
            </div>
          </div>
          <Component13 />
        </>
      )}
    </>
  );
};
export default Istoriya;

import React, { useState, useEffect } from 'react';
import '../../css/burgerMenu.css';
import window1 from '../../assest/svgEl/oknoMenu1.svg';
import window2 from '../../assest/svgEl/oknoMenu2.svg';
import arrow from '../../assest/svgEl/arrow.svg';
import arrowBlue from '../../assest/svgEl/arrowBlue.svg';
import BurgerMenuRS from './BurgerMenuRS';
import BurgerMenuMob from './BurgerMenuMob';
import logo from '../../assest/svgEl/logo.svg';
import { BrowserRouter as Router, Route, Link } from 'react-router-dom';

const BurgerMenu = ({ isOpen, toggleBM }) => {
  const classNames = isOpen ? 'burger-menu open' : 'burger-menu';

  const [isDesktop, setIsDesktop] = useState(window.innerWidth);

  const handleResize = () => {
    setIsDesktop(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const menu = [
    {
      name: 'Каталог окон',
      array: [
        { name: 'Пластиковые окна', array: [], link: '/not-found' },
        { name: 'Раздвижные окна', array: [], link: '/not-found' },
        {
          name: 'Нестандартные окна',
          array: [
            { name: 'Панорамные окна', array: [], link: '/not-found' },
            { name: 'Арочные окна', array: [], link: '/not-found' },
            { name: 'Эркерные окна', array: [], link: '/not-found' },
            { name: 'Бронированные окна', array: [], link: '/not-found' },
            { name: 'Треугольные окна', array: [], link: '/not-found' },
            { name: 'Окна нестандартной формы', array: [], link: '/not-found' },
            { name: 'Круглые окна', array: [], link: '/not-found' }
          ]
        },
        {
          name: 'Нестандартные цвет',
          array: [
            { name: 'Цветные пластиковые окна', array: [], link: '/not-found' },
            { name: 'Пластиковые окна под дерево', array: [], link: '/not-found' }
          ]
        },
        {
          name: 'Другие категории',
          array: [
            { name: 'Тонированные окна', array: [], link: '/not-found' },
            { name: 'Шумоизоляционные окна', array: [], link: '/not-found' },
            { name: 'Солнцезащитные окна', array: [], link: '/not-found' },
            { name: 'Окна со шпросами', array: [], link: '/not-found' },
            { name: 'Межкомнатные окна', array: [], link: '/not-found' }
          ]
        },
        {
          name: 'По типу конструкции',
          array: [
            { name: 'Одностворчатые окна', array: [], link: '/not-found' },
            { name: 'Двухстворчатые окна', array: [], link: '/not-found' },
            { name: 'Трехстворчатые окна', array: [], link: '/not-found' }
          ]
        },
        {
          name: 'По уровню тепла',
          array: [
            { name: 'Однокамерные стеклопакеты', array: [], link: '/not-found' },
            { name: 'Двухкамерные стеклопакеты', array: [], link: '/not-found' }
          ]
        },
        { name: 'Готовые решения', array: [], link: '/not-found' }
      ]
    },
    {
      name: 'Балконы и лоджии',
      array: [
        { name: 'Окна и двери на балкон', array: [], link: '/not-found' },
        { name: 'Утепление балкона', array: [], link: '/not-found' }
      ]
    },
    {
      name: 'Аксесуары и комплектующие',
      array: [
        { name: 'Замки на окна для безопасности детей', array: [], link: '/not-found' },
        { name: 'Комплектующие для пластиковых окон', array: [], link: '/not-found' },
        { name: 'Уплотнители для окон', array: [], link: '/not-found' },
        { name: 'Штапик', array: [], link: '/not-found' },
        { name: 'Заглушки для подоконника', array: [], link: '/not-found' },
        { name: 'Энергосберегающие стеклопакеты', array: [], link: '/not-found' },
        { name: 'Солнцезащитная пленка для окон', array: [], link: '/not-found' }
      ]
    },
    {
      name: 'Цены',
      array: [
        { name: 'Калькулятор окон', array: [], link: '/not-found' },
        // { name: 'Пластиковые окна в рассрочку', array: [], link: '/not-found' }
      ]
    },
    {
      name: 'О компании',
      array: [
        { name: 'О нас', array: [], link: '/o-nas' },
        { name: 'Наши работы', array: [], link: '/not-found' },
        { name: 'Вопрос-ответ', array: [], link: '/not-found' },
        { name: 'Бесплатный замер', array: [], link: '/not-found' },
        { name: 'Способы оплаты', array: [], link: '/not-found' },
        { name: 'Доставка', array: [], link: '/not-found' },
        { name: 'Гарантии', array: [], link: '/not-found' },
        { name: 'Сертификаты', array: [], link: '/not-found' },
        { name: 'Производство', array: [], link: '/not-found' },
        { name: 'Отзывы', array: [], link: '/not-found' }
      ]
    },
    {
      name: 'Диллерам',
      array: [],
      link: '/not-found'
    },
    {
      name: 'Стать партнером',
      array: [
        { name: 'Купить профиль', array: [], link: '/not-found' },
        { name: 'Стать партнером', array: [], link: '/not-found' },
        { name: 'Стать поставщиком', array: [], link: '/not-found' }
      ]
    },
    {
      name: 'Где купить',
      array: [],
      link: '/not-found'
    },
    {
      name: 'Контакты',
      array: [],
      link: '/kontakty'
    }
  ];

  const [centerMenu, setCenterMenu] = useState([]);
  const [rightMenu, setRightMenu] = useState([]);
  const [centerSectionStyle, setCenterSectionStyle] = useState('section section-center');
  const [rightSectionStyle, setRightSectionStyle] = useState('section section-right');
  const [activeIndex, setActiveIndex] = useState(null);
  const [activeIndexCenter, setActiveIndexCenter] = useState(null);

  const updateArrows = (obj) => {
    setCenterMenu(obj.array);
    setRightMenu([]);
    setCenterSectionStyle('section section-center-text');
    setRightSectionStyle('section section-right');
    setActiveIndex(obj.name);
    setActiveIndexCenter(null);
  };

  const centerFunc = (obj) => {
    setRightMenu(obj.array);
    setRightSectionStyle('section section-right-text');
    setActiveIndexCenter(obj.name);
  };

  const resetState = () => {
    toggleBM();
    setCenterMenu([]);
    setCenterSectionStyle('section section-center');
    setRightMenu([]);
    setRightSectionStyle('section section-right');
    setActiveIndex(null);
    setActiveIndexCenter(null);
  };

  return (
    <div>
      {' '}
      {
        isDesktop > 768 ? (
          <div className={classNames}>
            <div className="section section-left">
              <div>
                <img src={logo}></img>
              </div>

              <div>
                {menu.map((obj, index) => (
                  <div
                    key={index}
                    onClick={() => updateArrows(obj)}
                    className="test"
                    style={{ color: activeIndex !== obj.name ? '#161E54' : '#87DFEE' }}>
                    {obj.array.length > 0 ? (
                      <>
                        <span className="menu-text">
                          <span className={activeIndex !== obj.name ? 'text' : 'text_blue'}>
                            {obj.name}
                          </span>
                        </span>
                        <img
                          className="window1"
                          src={activeIndex !== obj.name ? arrow : arrowBlue}
                          alt="Arrow"
                          style={{ marginLeft: '10px' }}
                        />
                      </>
                    ) : (
                      <span className="menu-text">
                        {obj.link ? (
                          <Link to={obj.link}>
                            <span
                              className={activeIndex !== obj.name ? 'text' : 'text_blue'}
                              onClick={() => resetState()}>
                              {obj.name}
                            </span>
                          </Link>
                        ) : (
                          <span className={activeIndex !== obj.name ? 'text' : 'text_blue'}>
                            {obj.name}
                          </span>
                        )}
                      </span>
                    )}
                  </div>
                ))}
              </div>
            </div>
            <div className={centerSectionStyle}>
              <div>
                {centerMenu.length > 0 ? (
                  centerMenu.map((obj, index) => (
                    <div
                      key={index}
                      onClick={() => centerFunc(obj)}
                      className="test"
                      style={{ color: activeIndexCenter !== obj.name ? '#161E54' : '#87DFEE' }}>
                      {obj.array.length > 0 ? (
                        <>
                          <span className="menu-text">
                            <span className={activeIndexCenter !== obj.name ? 'text' : 'text_blue'}>
                              {obj.name}
                            </span>
                          </span>
                          <img
                            src={activeIndexCenter !== obj.name ? arrow : arrowBlue}
                            alt="Arrow"
                            style={{ marginLeft: '10px' }}
                          />
                        </>
                      ) : (
                        <span className="menu-text">
                          {obj.link ? (
                            <Link to={obj.link}>
                              <span
                                className={activeIndex !== obj.name ? 'text' : 'text_blue'}
                                onClick={() => resetState()}>
                                {obj.name}
                              </span>
                            </Link>
                          ) : (
                            <span className={activeIndex !== obj.name ? 'text' : 'text_blue'}>
                              {obj.name}
                            </span>
                          )}
                        </span>
                      )}
                    </div>
                  ))
                ) : (
                  <img alt="winwdow center" src={window1} />
                )}
              </div>

              <div className="vertical-line"></div>
            </div>
            <div className={rightSectionStyle}>
              <div>
                <div>
                  {rightMenu.length > 0 ? (
                    rightMenu.map((obj, index) => (
                      <div className="test">
                        <span className="menu-text" key={index}>
                          {obj.link ? (
                            <Link to={obj.link}>
                              <span
                                className={activeIndex !== obj.name ? 'text' : 'text_blue'}
                                onClick={() => resetState()}>
                                {obj.name}
                              </span>
                            </Link>
                          ) : (
                            <span className={activeIndex !== obj.name ? 'text' : 'text_blue'}>
                              {obj.name}
                            </span>
                          )}
                        </span>
                      </div>
                    ))
                  ) : (
                    <img alt="winwdow right" src={window2} />
                  )}
                </div>
              </div>
              <div className="vertical-line"></div>
              <button className="toggle-button" onClick={() => resetState()}>
                &times;
              </button>
            </div>
          </div>
        ) : isDesktop > 480 ? (
          <BurgerMenuRS isOpen={isOpen} toggleBM={toggleBM} menu={menu} />
        ) : (
          <BurgerMenuMob isOpen={isOpen} toggleBM={toggleBM} menu={menu} />
        )

        // здесь мобильная версия
      }
    </div>
  );
};

export default BurgerMenu;
